import { Ability } from "@casl/ability";

export const ability = new Ability();

export function abilityPlugin(store) {
  try {
    const oldStorage = JSON.parse(localStorage.getItem("vuex"));
    let rules = [];
    if (store.state.rules.length > 0) {
      rules = store.state.rules;
    } else if (oldStorage && oldStorage.rules) {
      rules = oldStorage.rules;
    }
    ability.update(rules);

    return store.subscribe((mutation) => {
      switch (mutation.type) {
        case "CREATE_SESSION_RULES":
          if (store?.state?.currentUser?.profile?.Username?.startsWith("IAM@")) {
            return ability.update([
              {
                actions: [
                  "create",
                  "download",
                  "inbound",
                  "outbound",
                  "listen",
                  "share",
                  "view",
                  "all",
                  "manage",
                  "edit",
                  "update",
                  "enable",
                  "remove",
                  "delete",
                ],
                subject: "all",
              },
            ]);
          } else {
            ability.update(mutation.payload);
          }
          break;
        case "destroySession":
          ability.update([{ actions: "read", subject: "all" }]);
          break;
      }
    });
  } catch (err) {
    console.error("err setting abilities: ", err);
  }
}
