import Vue from "vue";
import VueI18n from "vue-i18n";
import { languages, defaultLocale } from "@/i18n/index";

Vue.use(VueI18n);

const messages = Object.assign(languages);

export const i18n = new VueI18n({
  locale: defaultLocale,
  messages,
});
