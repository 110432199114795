import Vue from "vue";
import Router from "vue-router";
import { LoadingBar } from "view-design";
import store from "@/store";
import { isAuthenticated, hasSecurityPermissions } from "./validations";

const Users = () => import(/* webpackPrefetch: true */ "@/views/Users");
const Reports = () => import(/* webpackPrefetch: true */ "@/views/Reports");
const PostCallSurveys = () => import(/* webpackPrefetch: true */ "@/components/AdminSetting/PostCallSurveys/PostCallSurveys");
const Report = () => import(/* webpackPrefetch: true */ "@/components/Reports/Report");
const Activity = () => import(/* webpackPrefetch: true */ "@/views/Activity");
const ActivityAGGrid = () => import(/* webpackPrefetch: true */ "@/views/ActivityAGGrid/ActivityAGGrid");
const Contacts = () => import(/* webpackPrefetch: true */ "@/views/Contacts");
const Support = () => import(/* webpackPrefetch: true */ "@/views/Support");
const Diagnostics = () => import(/* webpackPrefetch: true */ "@/views/Diagnostics");
const CaseManagement = () => import(/* webpackPrefetch: true */ "@/views/CaseManagement");
const SolutionList = () => import(/* webpackPrefetch: true */ "@/views/SolutionList");
const AudioCheck = () => import(/* webpackPrefetch: true */ "@/views/AudioCheck");
const Call = () => import(/* webpackPrefetch: true */ "@/views/Call");
const Settings = () => import(/* webpackPrefetch: true */ "@/views/Settings");
const Master = () => import(/* webpackPrefetch: true */ "@/views/master/Main");
const NewDashboard = () => import(/* webpackPrefetch: true */ "@/views/Dashboard.new.vue");
const QueueRebalancing = () => import(/* webpackPrefetch: true */ "@/views/Rebalancing.vue");
const NewLandingPage = () => import(/* webpackPrefetch: true */ "@/views/Landing.new.vue");
const CCPParser = () => import(/* webpackPrefetch: true */ "@/views/CCPParser.vue");
Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Initial page",
      component: NewLandingPage,
      meta: {
        title: "Login",
        icon: "fa-home",
      },
    },
    {
      path: "/home",
      name: "master",
      redirect: "/dashboard",
      component: Master,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: NewDashboard,
          meta: {
            title: "Dashboard",
            icon: "fa-home",
            requiresAuth: true,
          },
        },
        {
          path: "/queues",
          name: "queues",
          component: QueueRebalancing,
          meta: {
            title: "Queues",
            icon: "fa-home",
            requiresAuth: true,
            securityProfile: ["general"],
          },
        },
        {
          path: "/parser",
          name: "CCPParser",
          component: CCPParser,
          meta: {
            title: "CCPParser",
            requiresAuth: true,
          },
        },
        {
          path: "/engage",
          name: "call",
          component: Call,
          meta: {
            title: "Engage",
            icon: "fa-headset",
            requiresAuth: true,
          },
        },
        {
          path: "/activity",
          name: "activity",
          component: Activity,
          meta: {
            title: "Activity",
            icon: "fa-clock",
            requiresAuth: true,
            securityProfile: ["own_activity", "others_activity", "same_queue"],
          },
        },
        {
          path: "/activitynew",
          name: "activitynew",
          component: ActivityAGGrid,
          meta: {
            title: "Activity",
            icon: "fa-clock",
            requiresAuth: true,
            securityProfile: ["own_activity", "others_activity", "same_queue"],
          },
        },
        {
          path: "/contacts",
          name: "contacts",
          component: Contacts,
          meta: {
            title: "Contacts",
            icon: "fa-address-book",
            requiresAuth: true,
            securityProfile: ["directory"],
          },
        },
        {
          path: "/support",
          name: "support",
          component: Support,
          meta: {
            title: "Support",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/diagnostics",
          name: "diagnostics",
          component: Diagnostics,
          meta: {
            title: "Diagnostics",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/case-management",
          name: "Case Management",
          component: CaseManagement,
          meta: {
            title: "Case Management",
            icon: "fa-address-book",
            requiresAuth: true,
            notAllowPartnerAccess: true,
          },
        },
        {
          path: "/solution-list",
          name: "Solution List",
          component: SolutionList,
          meta: {
            title: "Solution List",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/audio-check",
          name: "Audio Check",
          component: AudioCheck,
          meta: {
            title: "Audio Check",
            icon: "fa-address-book",
            requiresAuth: true,
          },
        },
        {
          path: "/reports",
          name: "reports",
          component: Reports,
          meta: {
            title: "Reports",
            icon: "fa-chart-area",
            requiresAuth: true,
            securityProfile: ["reporting"],
          },
          children: [
            {
              path: "/report/:id",
              name: "report",
              component: Report,
            },
          ],
        },
        {
          path: "/users",
          name: "users",
          component: Users,
          meta: {
            title: "Users",
            icon: "fa-users",
            requiresAuth: true,
            securityProfile: ["users"],
          },
        },
        {
          path: "/settings",
          name: "settings",
          component: Settings,
          meta: {
            title: "Settings",
            icon: "fa-cogs",
            requiresAuth: true,
          },
          children: [
            {
              path: "/settings/surveys",
              name: "post_call_surveys",
              component: PostCallSurveys,
              meta: {
                title: "Surveys",
                requiresAuth: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/:subdomain",
      name: "login template sub",
      beforeEnter(to, from, next) {
        if (store.getters["authentication/isAutenticatedInAmplify"]) {
          next({
            path: "/home",
            replace: true,
          });
        } else {
          next();
        }
      },
      component: NewLandingPage,
      meta: {
        title: "Login",
        icon: "fa-home",
      },
    },
  ],
});

LoadingBar.config({
  height: 4,
});

router.beforeResolve(async (to, from, next) => {
  if (!(await isAuthenticated(to))) {
    next({ path: "/" });
    return false;
  }
  if (!(await hasSecurityPermissions(to))) {
    next({ path: "/home" });
    return false;
  }
  next();
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title + " - ConnectPath";
  LoadingBar.start();
  next();
});

router.afterEach(() => {
  LoadingBar.finish();
});

export default router;
