export const GET_RECORDINGS = "getRecordings";
export const SET_RECORDINGS = "setRecordings";
export const IS_CONNECTED = "isConnected";
export const SET_CONNECTED = "setConnected";
export const FETCH_AGENT = "fetchAgent";
export const SET_AGENT = "setAgent";
export const SET_AGENT_ACTIVITY = "setAgentActivity";
export const NEW_STATE = "newState";
export const SET_STATE = "setState";
export const CREATE_CONTACT = "createContact";
export const FETCH_CONTACT = "fetchContact";
export const SET_CONTACT = "setContact";
export const ACCEPT_CONTACT = "acceptContact";
export const SET_ROLE = "setRole";
export const COMMIT_ROLE = "commitRole";
export const MUTE_AGENT = "muteAgent";
export const UNMUTE_AGENT = "unMuteAgent";
export const SET_CCP_INSTANCE = "setCcpInstanceId";
export const COMMIT_CCP_INSTANCE = "commitCcpInstanceId";
export const ON_INCOMING_CONTACT = "onIncomingContact";
export const SET_INCOMING_CONTACT = "setIncomingContact";
export const ON_ENDED_CONTACT = "onEndedContact";
export const ON_CONNECTED_CONTACT = "onConnectedContact";
export const ON_ACCEPTED_CONTACT = "onAcceptedContact";
export const HOLD_CONNECTIONS = "holdConnections";
export const RESUME_CONNECTIONS = "resumeConnections";
export const TRANSFER_CONNECTION = "transferConnection";
export const BLIND_TRANSFER_CONNECTION = "blindTransferConnection";
export const SET_CURRENT_USER = "setCurrentUser";
export const UPDATE_CURRENT_USER = "updateCurrentUser";
export const SAVE_CURRENT_USER = "saveCurrentUser";
export const CONFERENCE_CONNECTIONS = "conferenceConnections";
export const ADD_CONNECTION = "addConnection";
export const DOES_ROUTING_PROFILE_HAVE_QUEUES = "doesRoutingProfileHasQueues";

export const SET_ERROR = "setError";

export const SET_MODAL_ACTIVE = "setModalActive";
export const SET_VOLUMN_CONTROLL = "setVolumnControll";
export const SET_MIC_CONTROLL = "setMicControll";
export const SET_VOLUMN_SILENCE = "setVolumnSilence";
export const SET_MIC_SILENCE = "setMicSilence";
export const SET_SHOW_TEAM_STATUS = "setShowTeamStatus";
export const SET_SHOW_ALERTS_DRAWER = "setShowAlertsDrawer";

// NEW MUTATIONS
export const SET_ACTIVITY = "setActivity";
export const SET_QUEUE_METRICS = "setQueueMetrics";
export const SET_TEAM = "setTeam";
export const SET_HEALTH = "setHealth";
export const SET_USERS = "setUsers";
export const IS_FIRST_LOGIN = "isFirstLogin";
export const UPDATE_HEALTH_EVENTS = "updateHealthEvents";
export const SEND_DIGITS = "sendDigits";

// real-time actions
export const _SUBSCRIBE_NEW_AGENT = "_subscribeNewAgent";
export const _SET_NEW_AGENT = "_setNewAgent";

export const CHECK_FOR_NITRO = "checkForNitro";
