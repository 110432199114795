import axios from "axios";
import Vue from "vue";
import { Amplify } from "@aws-amplify/core";
import { getApiRestEndpoint, getAppSyncEndpoint } from "@/utils/configs";

Vue.use(Amplify);
Vue.prototype.$amplify = Amplify;

export default class AmplifyHandler {
  constructor() {
    if (AmplifyHandler.exists) {
      return AmplifyHandler.instance;
    }
    AmplifyHandler.instance = this;
    AmplifyHandler.exists = true;
  }

  async configure(region) {
    let resources = await axios({
      method: "post",
      url: `${getApiRestEndpoint(region)}/guest/getResources`,
    });

    const currentConfig = {
      aws_cognito_region: region,
      aws_user_pools_id: resources.data.cognito.userPoolId,
      aws_user_pools_web_client_id: resources.data.cognito.userPoolClientId,
      aws_cognito_identity_pool_id: resources.data.cognito.identityPoolId,
      aws_appsync_graphqlEndpoint: getAppSyncEndpoint(region),
      aws_appsync_region: region,
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
      aws_user_files_s3_bucket: resources.data.storageBucket,
      aws_user_files_s3_bucket_region: region,
    };

    try {
      return Amplify.configure({
        ...currentConfig,
        Auth: {
          refreshHandlers: async () => {
            return;
          },
        },
        Storage: {
          AWSS3: {
            bucket: `https://${currentConfig.aws_user_files_s3_bucket}.s3.amazonaws.com/`,
          },
        },
        graphql_headers: this.setHeaders(),
      });
    } catch (err) {
      return err;
    }
  }

  async authenticate(creds) {
    try {
      let auth = await Amplify.Auth.currentSession();
    } catch (err) {
      await Amplify.Auth.signIn(creds.CognitoUser, creds.CognitoPassword);
    }
    return Amplify.Auth.currentSession();
  }

  setHeaders() {
    return async () => {
      const { idToken } = await Amplify.Auth.currentSession();
      window.special_token = idToken;
      return { Authorization: idToken.jwtToken };
    };
  }

  async isAutenticated() {
    try {
      await Amplify.Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
