import store from "@/store";
import { ability } from "@/store/plugins/ability";
import { PARTNERS } from "@connectpath/common/src/constants";

export const isAuthenticated = async (to) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await store.dispatch("authentication/setSession");
    return store.getters["authentication/isAutenticatedInAmplify"] && !!localStorage.getItem("ccpUrl");
  }
  return true;
};

export const hasSecurityProfilePermissions = (to) => {
  if (to.matched.some((record) => record.meta.securityProfile)) {
    return to.meta.securityProfile.some((permission) => ability.can("view", permission));
  }
  return true;
};

export const hasPermissionsAsPartner = (to, partnerName) => {
  return partnerName !== PARTNERS.DIGITAL_ISLAND || !to.matched.some((record) => record.meta.notAllowPartnerAccess);
};

export const hasSecurityPermissions = (to) => {
  const partner = store.getters["ui/getPartnerTemplate"];
  return partner
    ? hasSecurityProfilePermissions(to) && hasPermissionsAsPartner(to, partner.partnerName)
    : hasSecurityProfilePermissions(to);
};
